<template>
  <el-form ref="form" :model="form" :rules="rules" class="p-8">
    <th-wrapper :title="$t('common.titles.general_info.title')">
      <div class="md:w-1/2 mb-8" style="color: #232e3d">
        <div
          class="text-sm font-semibold mb-8"
          :class="{ italic: !isWhiteLabel }"
          v-text="
            $t('pages.settings.account.edit.acccount_information.description')
          "
        />
        <div
          class="text-sm font-semibold mb-4"
          v-text="$t('pages.settings.account.edit.general_details.title')"
        />
      </div>
      <div>
        <div class="w-full" style="max-width: 335px">
          <el-form-item
            prop="firstname"
            :label="$t('pages.settings.account.edit.firstname.title')"
          >
            <el-input
              id="firstname"
              v-model="firstname"
              :placeholder="$t('pages.settings.account.edit.firstname.title')"
            />
          </el-form-item>
          <el-form-item
            prop="lastname"
            :label="$t('pages.settings.account.edit.lastname.title')"
          >
            <el-input
              id="lastname"
              v-model="lastname"
              :placeholder="$t('pages.settings.account.edit.lastname.title')"
            />
          </el-form-item>
          <el-form-item
            prop="phone"
            :label="$t('pages.settings.account.edit.phone.title')"
          >
            <el-input
              id="phone"
              v-model="phone"
              :placeholder="$t('pages.settings.account.edit.phone.title')"
            />
          </el-form-item>
        </div>
      </div>
    </th-wrapper>

    <th-wrapper
      :title="$t('pages.settings.account.edit.company_details.title')"
    >
      <div class="w-full" style="max-width: 490px">
        <el-form-item
          prop="company_name"
          :label="$t('pages.settings.account.edit.company_name.title')"
          class="w-full md:w-2/3"
        >
          <el-input
            id="company_name"
            v-model="company_name"
            :placeholder="$t('pages.settings.account.edit.company_name.title')"
          />
        </el-form-item>
        <div class="md:flex">
          <!-- Address information -->
          <address-row
            ref="address"
            v-model="localAddress"
            hide-delete-button
            :required-fields="requiredFields"
            @update:modelValue="updateAddress"
          />
        </div>

        <div class="mb-2">
          <el-form-item prop="tax_number" class="md:w-2/3">
            <label
              for="tax_number"
              class="el-form-item__label overflow-visible flex place-content-start"
            >
              <th-popover
                :text="$t('pages.settings.account.edit.tax_number.hint')"
                placement="top-start"
                class="pr-2"
              />
              {{ $t('pages.settings.account.edit.tax_number.title') }}</label
            >
            <el-input
              id="tax_number"
              v-model="tax_number"
              :placeholder="$t('pages.settings.account.edit.tax_number.title')"
            />
          </el-form-item>
        </div>
        <div class="mb-2">
          <el-form-item prop="vat_id" class="md:w-2/3">
            <label
              for="vat_id"
              class="el-form-item__label overflow-visible flex place-content-start"
            >
              <th-popover
                :text="$t('pages.settings.account.edit.vat_id.hint')"
                placement="top-start"
                class="pr-2"
              />
              {{ $t('pages.settings.account.edit.vat_id.title') }}</label
            >
            <el-input
              id="vat_id"
              v-model="vat_id"
              :placeholder="$t('pages.settings.account.edit.vat_id.title')"
            />
          </el-form-item>
        </div>
      </div>
    </th-wrapper>
  </el-form>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import get from 'just-safe-get'
import AddressRow from '@/components/tillhub/addresses-input-v2/address/address-row'
import { isUnifiedCommerce } from '@/constants'

export default {
  components: {
    AddressRow
  },
  data() {
    return {
      localAddress: {},
      requiredFields: [
        'street',
        'street_number',
        'locality',
        'postal_code',
        'country',
        'region'
      ],
      rules: {
        firstname: [
          {
            max: 128,
            message: this.$t(
              'pages.customers.edit.form.field_warnings.max_length',
              { length: 128 }
            )
          }
        ],
        lastname: [
          {
            max: 128,
            message: this.$t(
              'pages.customers.edit.form.field_warnings.max_length',
              { length: 128 }
            )
          }
        ],
        company_name: [
          {
            required: true,
            message: this.$t(
              'pages.settings.account.edit.rules.company_name.required'
            ),
            trigger: 'blur'
          }
        ],
        tax_number: [
          {
            required: true,
            message: this.$t(
              'pages.settings.account.edit.rules.tax_number.required'
            ),
            trigger: 'blur'
          }
        ]
      }
    }
  },
  computed: {
    ...mapGetters({
      clientAccountConfiguration: 'Config/getClientAccountConfiguration'
    }),
    ...mapState({
      email: (state) => state.Auth.username || '-',
      orgName: (state) => state.Auth.orgName || '-',
      expiresAt: (state) => state.Auth.expiresAt
    }),
    form() {
      return {
        firstname: this.firstname,
        lastname: this.lastname,
        phone: this.phone,
        tax_number: this.tax_number,
        vat_id: this.vat_id,
        company_name: this.company_name
      }
    },
    expiresAtFormatted() {
      return this.expiresAt
        ? this.$date.formatDateTimeWithTimezone(this.expiresAt)
        : '–'
    },
    firstname: {
      get() {
        return get(this.clientAccountConfiguration, 'tenant.firstname')
      },
      set(newValue) {
        this.$store.dispatch('Config/setClientAccountConfigurationValue', {
          path: 'tenant.firstname',
          value: newValue
        })
      }
    },
    lastname: {
      get() {
        return get(this.clientAccountConfiguration, 'tenant.lastname')
      },
      set(newValue) {
        this.$store.dispatch('Config/setClientAccountConfigurationValue', {
          path: 'tenant.lastname',
          value: newValue
        })
      }
    },
    phone: {
      get() {
        return get(this.clientAccountConfiguration, 'tenant.phone')
      },
      set(newValue) {
        this.$store.dispatch('Config/setClientAccountConfigurationValue', {
          path: 'tenant.phone',
          value: newValue
        })
      }
    },
    vat_id: {
      get() {
        return get(this.clientAccountConfiguration, 'tenant.vat_id')
      },
      set(newValue) {
        this.$store.dispatch('Config/setClientAccountConfigurationValue', {
          path: 'tenant.vat_id',
          value: newValue
        })
      }
    },
    tax_number: {
      get() {
        return get(this.clientAccountConfiguration, 'tenant.tax_number')
      },
      set(newValue) {
        this.$store.dispatch('Config/setClientAccountConfigurationValue', {
          path: 'tenant.tax_number',
          value: newValue
        })
      }
    },
    company_name: {
      get() {
        return get(this.clientAccountConfiguration, 'tenant.company_name')
      },
      set(newValue) {
        this.$store.dispatch('Config/setClientAccountConfigurationValue', {
          path: 'tenant.company_name',
          value: newValue
        })
      }
    },
    street: {
      get() {
        return get(this.clientAccountConfiguration, 'tenant.address.street')
      },
      set(newValue) {
        this.$store.dispatch('Config/setClientAccountConfigurationValue', {
          path: 'tenant.address.street',
          value: newValue
        })
      }
    },
    street_number: {
      get() {
        return get(
          this.clientAccountConfiguration,
          'tenant.address.street_number'
        )
      },
      set(newValue) {
        this.$store.dispatch('Config/setClientAccountConfigurationValue', {
          path: 'tenant.address.street_number',
          value: newValue
        })
      }
    },
    locality: {
      get() {
        return get(this.clientAccountConfiguration, 'tenant.address.locality')
      },
      set(newValue) {
        this.$store.dispatch('Config/setClientAccountConfigurationValue', {
          path: 'tenant.address.locality',
          value: newValue
        })
      }
    },
    postal_code: {
      get() {
        return get(
          this.clientAccountConfiguration,
          'tenant.address.postal_code'
        )
      },
      set(newValue) {
        this.$store.dispatch('Config/setClientAccountConfigurationValue', {
          path: 'tenant.address.postal_code',
          value: newValue
        })
      }
    },
    country: {
      get() {
        return get(this.clientAccountConfiguration, 'tenant.address.country')
      },
      set(newValue) {
        this.$store.dispatch('Config/setClientAccountConfigurationValue', {
          path: 'tenant.address.country',
          value: newValue
        })
      }
    },
    region: {
      get() {
        return get(this.clientAccountConfiguration, 'tenant.address.region')
      },
      set(newValue) {
        this.$store.dispatch('Config/setClientAccountConfigurationValue', {
          path: 'tenant.address.region',
          value: newValue
        })
      }
    },
    isWhiteLabel() {
      return isUnifiedCommerce()
    }
  },
  watch: {
    'clientAccountConfiguration.tenant': {
      deep: true,
      immediate: true,
      handler() {
        this.localAddress = {
          street: this.street,
          street_number: this.street_number,
          locality: this.locality,
          postal_code: this.postal_code,
          country: this.country,
          region: this.region
        }
      }
    }
  },
  mounted() {
    if (get(this.$route, 'query.fiskaly')) {
      this.validate(() => {})
    }
  },
  methods: {
    updateAddress(address) {
      //update each address property value if changed
      Object.keys(address).forEach((key) => {
        if (this[key] !== address[key]) {
          this[key] = address[key]
        }
      })
    },
    async validate(cb) {
      //validate form
      const formValid = await this.$refs.form.validate()
      //validate address
      const addressValid = await this.$refs.address.validate()

      const valid = formValid && addressValid
      if (!valid) {
        return this.$message({
          type: 'warning',
          message: this.$t(
            'common.forms.rules.field_warnings.invalid_inputs.required'
          )
        })
      }
      cb(valid)
    }
  }
}
</script>
