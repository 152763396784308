<template>
  <th-page-wrapper color="var(--secondary-color)">
    <account-form ref="form" />
    <template #actions>
      <settings-toolbar @save="handleSave" />
    </template>
  </th-page-wrapper>
</template>

<script>
import SettingsToolbar from '../components/settings-toolbar'
import AccountForm from './form'
import onboardingMixin from '@/mixins/onboarding'

export default {
  name: 'AccountSettings',
  metaInfo() {
    return {
      title: this.$t('nav.main.items.settings.account')
    }
  },
  components: {
    SettingsToolbar,
    AccountForm
  },
  beforeRouteUpdate(to, from, next) {
    //reset changes in settings that were not saved
    this.resetChanges()
    next()
  },
  beforeRouteLeave(to, from, next) {
    //reset changes in settings that were not saved
    this.resetChanges()
    next()
  },
  beforeRouteEnter(to, from, next) {
    if (from.name === 'resources-registers-edit') {
      to.query.redirect = from.path
      to.query.fiskaly = true
    }

    next()
  },
  setup() {
    const { onboardingConfirmed } = onboardingMixin()
    return {
      onboardingConfirmed
    }
  },
  methods: {
    checkLeave(cb) {
      if (this.canLeave) return cb(null, true)

      this.$confirm(
        this.$t('pages.settings.component.warnings.unsaved.message'),
        this.$t('pages.settings.component.warnings.unsaved.title'),
        {
          confirmButtonText: this.$t('common.interactions.buttons.ok'),
          cancelButtonText: this.$t('common.interactions.buttons.cancel'),
          type: 'warning'
        }
      )
        .then(() => {
          this.resetChanges()
          return cb(null, true)
        })
        .catch((err) => {
          return cb(err, false)
        })
    },
    async handleSave() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) return

        const successMessage = this.$t('common.success.action.update.single', {
          resource: this.$t('common.resource.configuration.singular')
        })
        const errorMessage = this.$t('common.error.action.update.single', {
          resource: this.$t('common.resource.configuration.singular')
        })

        const checkOnboarding = !this.onboardingConfirmed('account_information')
        // Check if its the first account and change the onboarding value
        if (checkOnboarding) {
          this.$store.dispatch('Config/setClientAccountConfigurationValue', {
            path: 'onboarding.stages.account_information',
            value: true
          })
          this.$store.dispatch('Config/checkOnboardingCompleted')
        }

        this.$store.dispatch('Config/prepareData')
        const [err] = await this.$store.dispatch(
          'Config/saveClientAccountConfiguration'
        )

        if (err) {
          return this.$logException(err, {
            message: errorMessage
          })
        }

        this.$message({
          type: 'success',
          message: successMessage
        })

        // Fix for showing the onboarding menu after completing the step
        if (checkOnboarding) {
          this.$store.dispatch('Config/checkOnboardingCompleted')
        }

        this.$nextTick(this.checkForRedirect)
      })
    },
    checkForRedirect() {
      if (this.$route.query.fiskaly) {
        this.$router.push(this.$route.query.redirect + '?fiskaly=start')
      }
    },
    resetChanges() {
      this.$store.dispatch('Config/resetClientAccountConfiguration')
      // Could be that the language was changed and not saved
      this.$rootMaybeChangeLocale(this.$store.getters['Config/getLocale'])
    }
  }
}
</script>
